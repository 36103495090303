
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useToggle } from '@vant/use'
import { useRouter } from 'vue-router'
import { Dialog } from 'vant'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { setApptitle } from '@/utils/native-app'
import { queryFn } from '@/graphql'

export default defineComponent({
  setup () {
    const router = useRouter()
    const currentDate = ref(new Date())
    // 查询数据字典
    const dicResult = ref()
    // vuex store数据
    const { openType, openInfo, meta, title, step, saveOpenInfoOfVuex, saveCommonOfVuex } =
      useOpenInfo()
    // 投资经验股票picker
    const [showStockPicker, showStockPickerToggle] = useToggle()
    // 投资经验债券
    const [showBondPicker, showBondPickerToggle] = useToggle()
    // 投资衍生品
    const [showDerivativesPicker, showDerivativesPickerToggle] = useToggle()
    // 结构化
    const [showOtcPicker, showOtcPickerToggle] = useToggle()
    // 信托
    const [showTrustPicker, showTrustPickerToggle] = useToggle()
    // 投资其他
    const [showOtherPicker, showOtherPickerToggle] = useToggle()
    // 资产项目
    const [showAssetPicker, showAssetPickerToggle] = useToggle()
    // 资产净值
    const [showNetPicker, showNetPickerToggle] = useToggle()
    // 每年收入
    const [showIncomePicker, showIncomePickerToggle] = useToggle()
    // 衍生品icon
    const [showWarrantsPop, showWarrantsPopToggle] = useToggle()
    // 预期投入金额
    const [showAmountPicker, showAmountPickerToggle] = useToggle()
    // 参加课程年份
    const [showDatePicker, showDatePickerToggle] = useToggle()
    // 任职开始时间
    const [showStartDatePicker, showStartDatePickerToggle] = useToggle()
    // 任职结束时间
    const [showEndDatePicker, showEndDatePickerToggle] = useToggle()

    const form: any = reactive({
      assetSource: [],
      annualIncomeText: '',
      annualIncomeIdx: '',
      annualIncome: '',
      // otherInvestmentName: '',
      assetSourceOther: '',
      netAssetText: '',
      netAssetIdx: '',
      netAsset: '',
      investTarget: [],
      investTargetOther: '',
      assetItemText: '',
      assetItemOther: '',
      // assetItemValue: '',
      assetItem: [],
      stockInvestmentExperienceTypeText: '',
      stockInvestmentExperienceTypeIdx: '',
      stockInvestmentExperienceType: 1,
      bondInvestmentExperienceTypeText: '',
      bondInvestmentExperienceTypeIdx: '',
      bondInvestmentExperienceType: 1,
      warrantsInvestmentExperienceTypeText: '',
      warrantsInvestmentExperienceTypeIdx: '',
      warrantsInvestmentExperienceType: '',
      // otherInvestmentExperienceTypeText: '',
      otherInvestmentExperienceTypeIdx: '',
      otherInvestmentExperienceType: '',
      expectedYearInvestmentAmount: '', // 预期每年投入金额
      expectedYearInvestmentAmountText: '',
      expectedYearInvestmentAmountIdx: '',
      isTradeDerivativeProducts: 0,
      isKnowDerivativeProducts: 0, // 衍生品认知
      derivativeCheck: [11],
      otcInvestmentExperienceTypeText: '', // 结构化产品
      otcInvestmentExperienceTypeIdx: '',
      otcInvestmentExperienceType: 1,
      trustInvestmentExperienceTypeText: '',
      trustInvestmentExperienceTypeIdx: '', // 单位信托
      trustInvestmentExperienceType: 1,
      annualIncomeOther: '',
      netAssetOther: '',
      expectedYearInvestmentAmountOther: '',
      derivativeInvestmentExperienceTypeText: '没有', // 衍生权证
      derivativeInvestmentExperienceType: 1,
      bullBearInvestmentExperienceTypeText: '没有', // 牛熊证
      bullBearInvestmentExperienceType: 1,
      etfInvestmentExperienceTypeText: '没有', // 交易所买卖基金
      etfInvestmentExperienceType: 1,
      leverInvestmentExperienceTypeText: '没有', // 杠杆及反向产品
      leverInvestmentExperienceType: 1,
      fcnInvestmentExperienceTypeText: '没有', // 固定派息票据
      fcnInvestmentExperienceType: 1,
      elnInvestmentExperienceTypeText: '没有', // 股票挂钩票据
      elnInvestmentExperienceType: 1,
      aqDqInvestmentExperienceTypeText: '没有', // 累计认购期权/累计认沽期权
      aqDqInvestmentExperienceType: 1,
      pbInvestmentExperienceTypeText: '没有', // 参与式票据
      pbInvestmentExperienceType: 1,
      ibInvestmentExperienceTypeText: '没有', // 区间票据
      ibInvestmentExperienceType: 1,
      trsInvestmentExperienceTypeText: '没有', // 总收益互换
      trsInvestmentExperienceType: 1,
      scnInvestmentExperienceTypeText: '没有', // 雪球派息票据
      scnInvestmentExperienceType: 1,
      otcOptionInvestmentExperienceTypeText: '没有', // 场外期权
      otcOptionInvestmentExperienceType: 1,
      trustFundInvestmentExperienceTypeText: '没有', // 单位信托或互惠基金
      trustFundInvestmentExperienceType: 1,
      privateFundInvestmentExperienceTypeText: '没有', // 私募基金
      privateFundInvestmentExperienceType: 1,
      otherInvestment: [
        {
          nanoid: 1,
          otherInvestmentExperienceType: 2,
          otherInvestmentName: ''
        }
      ] // 其他投资经验
    })
    // 衍生品认知
    const derivativeCode9: any = reactive({
      derivativeCode: 9, // 衍生品认知编号
      derivativeValue: 0, // 衍生品认知属性[0-否 1-是]
      derivativeDetail1: '', // 课程
      derivativeDetail2: '', // 组织者
      derivativeDetail3: '' // 时间
    })
    const derivativeCode10: any = reactive({
      derivativeCode: 10,
      derivativeValue: 0,
      derivativeDetail1: '', // 公司
      derivativeDetail2: '1', // 时间
      startDate: '',
      endDate: '',
      derivativeDetail3: '', // 职位
      derivativeDetail4: '' // 部门
    })
    const derivativeCode11 = reactive({
      derivativeCode: 11,
      derivativeValue: 1
    })
    // 财务状况列表
    const assetSourceOptions = ref()
    const assetSourceFn = () => {
      let assetList = dicResult.value.ASSET_SOURCE.map((val: { name: string; value: number }) => ({
        name: Number(val.value),
        text: val.name
      }))
      assetList = assetList.filter((val: { name: number }) => val.name !== 9)
      let array
      switch (openInfo.value.professionCode) {
        case 1:
        // falls through
        case 3:
          array = assetList.filter((val: { name: number }) => val.name !== 7 && val.name !== 6)
          break
        case 2:
          array = assetList.filter((val: { name: number }) => val.name !== 1 && val.name !== 6)
          break
        case 4:
        // falls through
        case 6:
          array = assetList.filter(
            (val: { name: number }) =>
              val.name !== 1 && val.name !== 4 && val.name !== 7 && val.name !== 6
          )
          break
        case 5:
          array = assetList.filter(
            (val: { name: number }) => val.name !== 1 && val.name !== 4 && val.name !== 7
          )
          break
        default:
          array = assetList
          break
      }
      return array
    }

    // 投资目标列表
    const investTargetOptions = ref()
    // 资产项目列表
    const assetOptions = ref()
    // 经验年限列表
    const yearOptions = ref()
    // 其他年限列表
    const otherYearOptions = ref()
    // 每年收入
    const annualIncomeOptions = ref()
    // 预期每年投资金额
    const amountOptions = ref()
    // 资产净值
    const netAssetOptions = ref()
    // 用户信息回填
    const initData = async () => {
      Object.keys(form).forEach((val) => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
      if (form.derivativeCheck.includes(9)) {
        const res = openInfo.value?.derivativeCode9
          ? openInfo.value?.derivativeCode9
          : derivativeCode9
        Object.keys(derivativeCode9).forEach((val) => {
          derivativeCode9[val] = res[val]
        })
      }
      if (form.derivativeCheck.includes(10)) {
        const res = openInfo.value?.derivativeCode10
          ? openInfo.value?.derivativeCode10
          : derivativeCode10
        Object.keys(derivativeCode10).forEach((val) => {
          derivativeCode10[val] = res[val]
        })
      }
      const res = await queryFn('findDictOpenAccount', {
        markList: [
          'ASSET_SOURCE',
          'INVEST_TARGET',
          'INVESTMENT_EXPERIENCE_TYPE',
          'NET_ASSET',
          'ANNUAL_INCOME',
          'ASSET_ITEM',
          'EXPECTED_YEAR_INVESTMENT_AMOUNT'
        ]
      })
      dicResult.value = res?.data
      assetSourceOptions.value = assetSourceFn()
      investTargetOptions.value = dicResult.value.INVEST_TARGET.map(
        (val: { name: string; value: string }) => ({
          name: Number(val.value),
          text: val.name
        })
      )
      assetOptions.value = dicResult.value.ASSET_ITEM.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: Number(val.value)
        })
      )
      yearOptions.value = dicResult.value.INVESTMENT_EXPERIENCE_TYPE.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: Number(val.value)
        })
      )

      otherYearOptions.value = yearOptions.value?.filter((val:any) => val.name !== 1)

      annualIncomeOptions.value = dicResult.value.ANNUAL_INCOME.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: Number(val.value)
        })
      )
      netAssetOptions.value = dicResult.value.NET_ASSET.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: Number(val.value)
        })
      )
      amountOptions.value = dicResult.value.EXPECTED_YEAR_INVESTMENT_AMOUNT.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          name: Number(val.value)
        })
      )
    }

    const onIncomeConfirm = (val: { text: string; name: number }, idx: number) => {
      form.annualIncomeText = val.text
      form.annualIncome = val.name
      form.annualIncomeIdx = String(idx)
      showIncomePickerToggle(false)
    }
    const onNetConfirm = (val: { text: string; name: number }, idx: number) => {
      form.netAssetText = val.text
      form.netAsset = val.name
      form.netAssetIdx = String(idx)
      showNetPickerToggle(false)
    }
    // 投资股票
    const onStockConfirm = (val: { text: string; name: number }, idx: number) => {
      form.stockInvestmentExperienceTypeText = val.text
      form.stockInvestmentExperienceType = val.name
      form.stockInvestmentExperienceTypeIdx = String(idx)
      showStockPickerToggle(false)
    }
    // 投资债券
    const onBondConfirm = (val: { text: string; name: number }, idx: number) => {
      form.bondInvestmentExperienceTypeText = val.text
      form.bondInvestmentExperienceType = val.name
      form.bondInvestmentExperienceTypeIdx = String(idx)
      showBondPickerToggle(false)
    }
    // 投资衍生品
    const onDerivativesConfirm = (val: { text: string; name: number }, idx: number) => {
      form.warrantsInvestmentExperienceTypeText = val.text
      form.warrantsInvestmentExperienceType = val.name
      form.warrantsInvestmentExperienceTypeIdx = String(idx)
      showDerivativesPickerToggle(false)
    }
    // 结构化
    const onOtcConfirm = (val: { text: string; name: number }, idx: number) => {
      form.otcInvestmentExperienceTypeText = val.text
      form.otcInvestmentExperienceType = val.name
      form.otcInvestmentExperienceTypeIdx = String(idx)
      showOtcPickerToggle(false)
    }
    const onTrustConfirm = (val: { text: string; name: number }, idx: number) => {
      form.trustInvestmentExperienceTypeText = val.text
      form.trustInvestmentExperienceType = val.name
      form.trustInvestmentExperienceTypeIdx = String(idx)
      showTrustPickerToggle(false)
    }
    // 预期每年投入金额
    const onAmountConfirm = (val: { text: string; name: number }, idx: number) => {
      form.expectedYearInvestmentAmountText = val.text
      form.expectedYearInvestmentAmount = val.name
      form.expectedYearInvestmentAmountIdx = String(idx)
      showAmountPickerToggle(false)
    }
    // 参加课程年份
    const onDateConfirm = (date: Date) => {
      const year = date.getFullYear()
      derivativeCode9.derivativeDetail3 = year.toString()
      showDatePickerToggle(false)
    }
    const year = dayjs().year() - 100
    const minDate = new Date(year, 0, 1)
    const minEndDate = ref(new Date(year, 0, 1))
    // 任职时段
    const formatDate = (date: Date) => {
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      return `${date.getFullYear()}-${month}-${day}`
    }
    const onStartDateConfirm = (date: Date) => {
      derivativeCode10.startDate = formatDate(date)
      minEndDate.value = date
      showStartDatePickerToggle(false)
    }
    const onEndDateConfirm = (date: Date) => {
      derivativeCode10.endDate = formatDate(date)
      showEndDatePickerToggle(false)
    }
    const formatter = (type: string, val: string) => {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      if (type === 'day') {
        return `${val}日`
      }
      return val
    }
    // 投资其他
    // const onOtherConfirm = (val: { text: string; name: number }, idx: number) => {
    //   form.otherInvestmentExperienceTypeText = val.text
    //   form.otherInvestmentExperienceType = val.name
    //   form.otherInvestmentExperienceTypeIdx = String(idx)
    //   showOtherPickerToggle(false)
    // }

    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const filterOpts = assetSourceOptions.value.map(
        (val: { text: string; name: number }) => val.name
      )
      const filterAsset = form.assetSource.map((item: number) =>
        filterOpts.includes(item) ? item : null
      )
      form.assetSource = filterAsset.filter((item: number) => item !== null)
      derivativeCode10.derivativeDetail2 = JSON.stringify([
        derivativeCode10.startDate,
        derivativeCode10.endDate
      ])
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify({
          ...form,
          derivativeCode9,
          derivativeCode10,
          derivativeCode11
        })
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({
          info: { derivativeCode9, derivativeCode10, derivativeCode11, ...form }
        })
        router.push({ name: 'tax-info' })
      })
    }

    const handleNext = () => {
      if (form.isTradeDerivativeProducts === 1 && form.isKnowDerivativeProducts === 0) {
        Dialog.confirm({
          title: '衍生品披露声明',
          allowHtml: true,
          message: `您本人同意及确认本人未有衍生工具知识但有意买卖衍生工具产品，且同意金马证券有限公司及/或其持牌人没有就拟进行的衍生工具交易向客户作出招揽或建议行爲。
            <br/>此外，本人确认金马证券有限公司及/或其持牌人已向客户解释买卖衍生工具产品所附带的相关风险。客户清楚明白相关衍生工具产品的风险及愿意承担交易相关衍生工具产品所带来的潜在风险。`,
          cancelButtonText: '不同意',
          confirmButtonText: '同意'
        })
          .then(() => {
            saveNext()
          })
          .catch(() => {
            // on cancel
          })
        return
      }
      saveNext()
    }

    const afterRead = () => {
      //
    }

    // 衍生品
    const derivativeOptions = ref([
      { name: 1, text: '有' },
      { name: 0, text: '没有' }
    ])
    watch(
      () => form.derivativeCheck,
      (val) => {
        if (val.includes(9)) {
          derivativeCode9.derivativeValue = 1
        } else {
          derivativeCode9.derivativeValue = 0
        }
        if (val.includes(10)) {
          derivativeCode10.derivativeValue = 1
        } else {
          derivativeCode10.derivativeValue = 0
        }
        if (val.includes(11)) {
          derivativeCode11.derivativeValue = 1
        } else {
          derivativeCode11.derivativeValue = 0
        }
      }
    )

    onMounted(() => {
      setApptitle(title)
      initData()
    })
    // todo需要重新定义
    const isDisabled = computed(() => {
      let result =
        form.assetSource.length &&
        form.investTarget.length &&
        form.expectedYearInvestmentAmountText !== '' &&
        // form.stockInvestmentExperienceTypeText !== '' &&
        // form.bondInvestmentExperienceTypeText !== '' &&
        // form.warrantsInvestmentExperienceTypeText !== '' &&
        form.annualIncomeText !== '' &&
        // form.otcInvestmentExperienceTypeText !== '' &&
        // form.trustInvestmentExperienceTypeText !== '' &&
        form.assetItem.length &&
        form.netAssetText !== ''
      // result =
      //   form.otherInvestmentName === ''
      //     ? result
      //     : result && form.otherInvestmentExperienceTypeText !== ''
      if (form.investTarget.includes(5)) {
        result = result && form.investTargetOther !== ''
      }
      if (form.assetSource.includes(10)) {
        result = result && form.assetSourceOther !== ''
      }
      if (form.assetItem.includes(5)) {
        result = result && form.assetItemOther !== ''
      }
      if (form.annualIncome === 6) {
        result = result && form.annualIncomeOther !== ''
      }
      if (form.netAsset === 7) {
        result = result && form.netAssetOther !== ''
      }
      if (form.expectedYearInvestmentAmount === 7) {
        result = result && form.expectedYearInvestmentAmountOther !== ''
      }
      if (form.isTradeDerivativeProducts === 1 && form.isKnowDerivativeProducts === 1) {
        // 复选框必须勾选一个
        result = result && form.derivativeCheck.length
        if (form.derivativeCheck.includes(9)) {
          result = result && Object.values(derivativeCode9).every((val) => val !== '')
        }
        if (form.derivativeCheck.includes(10)) {
          result = result && Object.values(derivativeCode10).every((val) => val !== '')
        }
      }
      // console.log(result);
      return !result
    })

    // 新增其他
    const addOther = () => {
      if (form.otherInvestment.length >= 5) {
        return
      }
      const other = {
        nanoid: nanoid(),
        otherInvestmentName: '',
        otherInvestmentExperienceType: 2
      }
      form.otherInvestment.push(other)
    }

    const removeOther = (v: any) => {
      form.otherInvestment = form.otherInvestment.filter((item: any) => item.nanoid !== v.nanoid)
    }

    return {
      initData,
      form,
      derivativeCode9,
      derivativeCode10,
      derivativeCode11,
      handleNext,
      isDisabled,
      assetSourceOptions,
      derivativeOptions,
      amountOptions,
      currentDate,
      // assetSources,
      // assetItem,
      // netAssetText,
      // otherInvestmentName,
      investTargetOptions,
      assetOptions,
      onIncomeConfirm,
      onNetConfirm,
      afterRead,
      showAssetPicker,
      showAssetPickerToggle,
      showNetPicker,
      showNetPickerToggle,
      showIncomePicker,
      showIncomePickerToggle,
      yearOptions,
      otherYearOptions,
      annualIncomeOptions,
      netAssetOptions,
      showWarrantsPop,
      showWarrantsPopToggle,
      // showPop,
      // showPopToggle,
      showStockPicker,
      showStockPickerToggle,
      onStockConfirm,
      showBondPicker,
      showBondPickerToggle,
      onBondConfirm,
      showDerivativesPicker,
      showDerivativesPickerToggle,
      onDerivativesConfirm,
      showOtcPicker,
      showOtcPickerToggle,
      onOtcConfirm,
      showTrustPicker,
      showTrustPickerToggle,
      onTrustConfirm,
      onAmountConfirm,
      showAmountPicker,
      showAmountPickerToggle,
      onDateConfirm,
      showDatePicker,
      showDatePickerToggle,
      formatter,
      minDate,
      minEndDate,
      currDate: new Date(),
      onStartDateConfirm,
      showStartDatePicker,
      showStartDatePickerToggle,
      onEndDateConfirm,
      showEndDatePicker,
      showEndDatePickerToggle,
      showOtherPicker,
      showOtherPickerToggle,
      // onOtherConfirm,
      maxDate: new Date(),
      addOther,
      removeOther
    }
  }
})
